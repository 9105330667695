<template>
  <div class="drawPadContainer" ref="container" :style="{ width: width, height: height }">
    <img v-if="imageUrl" :src="imageUrl" />
    <div v-if="isNew">
      <a class="imageDelete" title="Clear Image" @click="clearImage"><b-icon icon="delete"/></a>
      <canvas
        ref="myCanvas"
        @mousedown.stop.prevent="beginDrawing"
        @mousemove.stop.prevent="keepDrawing"
        @mouseup.stop.prevent="stopDrawing"
        @mouseleave.stop.prevent="startLeave"
        @mouseenter.stop.prevent="stopLeave"
        @touchstart.stop.prevent="touchstart"
        @touchend.stop.prevent="touchend"
        @touchmove.stop.prevent="touchmove"
        :width="width"
        :height="height"
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    width: { type: String, default: "100%" },
    height: { type: String, default: "100px" },
    imageUrl: { type: String, default: null },
    isNew: { type: Boolean, default: true }
  },
  data() {
    return {
      mousePos: { x: 0, y: 0 },
      isDrawing: false,
      canvas: null,
      ctx: null,
      paddingOffset: 5,
      cx: 0,
      cy: 0,
      leftEvent: null
    };
  },
  methods: {
    drawLine(current, newPos) {
      if (!newPos) return;
      const ctx = this.ctx;
      ctx.beginPath();
      ctx.strokeStyle = "black";
      ctx.lineWidth = 1;
      ctx.moveTo(current.x, current.y);
      ctx.lineTo(newPos.x, newPos.y);
      ctx.stroke();
      ctx.closePath();
    },
    beginDrawing(e) {
      this.mousePos = this.getMousePos(e);
      this.isDrawing = true;
    },
    keepDrawing(e) {
      if (this.isDrawing === true) {
        const newPos = this.getMousePos(e);
        this.drawLine(this.mousePos, newPos);
        this.mousePos = newPos;
      }
    },
    stopDrawing(e) {
      if (this.isDrawing === true) {
        this.isDrawing = false;
        this.updateImageData();
      }
    },
    getMousePos(mouseEvent) {
      var rect = this.canvas.getBoundingClientRect();
      // prettier-ignore
      return mouseEvent
        ? {
          x: mouseEvent.clientX - rect.left,
          y: mouseEvent.clientY - rect.top
        }
        : null;
    },
    // When user leaves area, we set timing to stop drawing. This allows user to accidentally go outside area and back in again without drawing stop
    // but if the deliberately leave, the  draw won't continue automatically when then mouse back over
    startLeave() {
      if (!this.isDrawing) return;
      this.leftEvent = setTimeout(() => {
        this.stopDrawing();
      }, 1000);
    },
    stopLeave() {
      if (!this.isDrawing) return;
      clearTimeout(this.leftEvent);
    },
    touchstart(e) {
      var touch = e.touches[0];
      var mouseEvent = new MouseEvent("mousedown", {
        clientX: touch.clientX,
        clientY: touch.clientY
      });
      this.canvas.dispatchEvent(mouseEvent);
    },
    touchend(e) {
      var mouseEvent = new MouseEvent("mouseup", {});
      this.canvas.dispatchEvent(mouseEvent);
    },
    touchmove(e) {
      var touch = e.touches[0];
      var mouseEvent = new MouseEvent("mousemove", {
        clientX: touch.clientX,
        clientY: touch.clientY
      });
      this.canvas.dispatchEvent(mouseEvent);
    },
    clearImage() {
      this.ctx.clearRect(0, 0, this.cx, this.cy);
    },
    updateImageData() {
      const c = this.$refs.myCanvas;
      c.toBlob(blob => {
        this.$emit("input", blob);
      });
    }
  },
  mounted() {
    this.canvas = this.$refs.myCanvas;
    if (this.canvas) {
      this.ctx = this.canvas.getContext("2d");

      // set canvas size to container size
      const r = this.$refs.container;
      this.cx = r.clientWidth - this.paddingOffset * 2;
      this.canvas.width = this.cx;

      this.cy = r.clientHeight - this.paddingOffset * 2;
      // for height %
      if (this.height.slice(this.height.length - 1) === "%") {
        const h = this.height.slice(0, this.height.length - 1);
        this.cy = (h * window.innerHeight) / 100 - this.paddingOffset;
      }
      this.canvas.height = this.cy;
    }
  }
};
</script>

<style scoped>
.drawPadContainer {
  border: 3px black solid;
  margin: 15px auto;
  padding: 5px;
  position: relative;
  min-height: 80px;
}

.imageDelete {
  position: absolute;
  top: 0;
  right: 0;
}
</style>
