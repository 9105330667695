<template>
  <AssessmentForm
    :assessmentDate="form.assessmentDate"
    :score="score"
    :rating="rating"
    :assessmentId="form.assessmentId"
    :assessmentType="assessmentType"
    :form="form"
    :formPostData="formPostData"
    :readonly="readonly"
    :saveDisabled="saveDisabled"
    @setResidentData="setResidentData"
    @setAssessmentDate="setAssessmentDate"
    @mapServerData="mapServerData"
    @dataSaved="dataSaved"
    ref="AssessmentForm"
  >
    <CardComponent title="Orientation" class="primaryCard" ref="Orientation">
      <div class="columns">
        <div class="column">
          <p class="field">Ask the Resident the following questions and mark if correct or not.</p>
          <b-field label="What is the Current Year" horizontal class="label-long">
            <CorrectPicker v-model="form.currentYear" name="year" :disabled="readonly" required />
          </b-field>
          <b-field label="Current Season" horizontal class="label-long">
            <CorrectPicker v-model="form.currentSeason" name="season" :disabled="readonly" required />
          </b-field>
          <b-field label="Current Date" horizontal class="label-long">
            <CorrectPicker v-model="form.currentDate" name="date" :disabled="readonly" required />
          </b-field>
          <b-field label="Current Day" horizontal class="label-long">
            <CorrectPicker v-model="form.currentDay" name="day" :disabled="readonly" required />
          </b-field>
          <b-field label="Current Month" horizontal class="label-long">
            <CorrectPicker v-model="form.currentMonth" name="month" :disabled="readonly" required />
          </b-field>
        </div>
        <div class="column">
          <p class="field">Where are we?</p>
          <b-field label="Country" horizontal>
            <CorrectPicker v-model="form.country" name="country" :disabled="readonly" required />
          </b-field>
          <b-field label="County" horizontal>
            <CorrectPicker v-model="form.county" name="county" :disabled="readonly" required />
          </b-field>
          <b-field label="Town / City" horizontal>
            <CorrectPicker v-model="form.city" name="city" :disabled="readonly" required />
          </b-field>
          <b-field label="Building" horizontal>
            <CorrectPicker v-model="form.building" name="building" :disabled="readonly" required />
          </b-field>
          <b-field label="Floor" horizontal>
            <CorrectPicker v-model="form.floor" name="floor" :disabled="readonly" required />
            <a
              v-if="!form.showOrientationNotes && !form.orientationNotes && !readonly"
              @click.prevent="showNotes('showOrientationNotes', 'orientationNotes')"
            >
              <b-icon class="field-label" icon="message" title="Add notes" />
            </a>
          </b-field>
        </div>
      </div>
      <b-input
        v-if="form.showOrientationNotes || form.orientationNotes"
        v-model="form.orientationNotes"
        placeholder="Enter notes or observations"
        ref="orientationNotes"
        :disabled="readonly"
      />
    </CardComponent>

    <CardComponent title="Registration" class="primaryCard">
      <p class="field">
        Name 3 common Objects<br />
        Take 1 second to say each. Then ask the resident to repeat all 3 after you have said them. Then repeat them until he/she learns all
        3. Count trials and record.
      </p>

      <b-field label="e.g. Apple" horizontal class="label-long">
        <CorrectPicker v-model="form.object1" name="object1" :disabled="readonly" required />
      </b-field>
      <b-field label="e.g. Table" horizontal class="label-long">
        <CorrectPicker v-model="form.object2" name="object2" :disabled="readonly" required />
      </b-field>
      <b-field label="e.g. Penny" horizontal class="label-long">
        <CorrectPicker v-model="form.object3" name="object3" :disabled="readonly" required />
      </b-field>
      <b-field label="No. Trials" horizontal class="label-long">
        <b-numberinput v-model="form.trials" :controls="false" required :disabled="readonly" :min="1" :max="20" class="inputFixedWidth-1" />
        <a
          v-if="!form.showRegistrationNotes && !form.registrationNotes && !readonly"
          @click.prevent="showNotes('showRegistrationNotes', 'registrationNotes')"
        >
          <b-icon class="field-label" icon="message" title="Add notes" />
        </a>
      </b-field>
      <b-input
        v-if="form.showRegistrationNotes || form.registrationNotes"
        v-model="form.registrationNotes"
        placeholder="Enter notes or observations"
        ref="registrationNotes"
        :disabled="readonly"
      />
    </CardComponent>

    <CardComponent title="Attention & Concentration" class="primaryCard">
      <ValidationObserver>
        <div class="columns">
          <div class="column">
            <ValidationProvider rules="notBoth:@skipSerial" v-slot="{ errors }">
              <div class="columns">
                <div class="column">
                  <b-field label="Spell world backwards." :type="{ 'is-danger': errors[0] }" :message="errors" />
                </div>
                <div class="column">
                  <b-checkbox v-model="form.skipWorld">Skip</b-checkbox>
                </div>
              </div>
            </ValidationProvider>
            <div v-if="!form.skipWorld">
              <b-field label="D" horizontal class="label-long">
                <CorrectPicker v-model="form.world1" name="world1" :disabled="readonly" required />
              </b-field>
              <b-field label="L" horizontal class="label-long">
                <CorrectPicker v-model="form.world2" name="world2" :disabled="readonly" required />
              </b-field>
              <b-field label="R" horizontal class="label-long">
                <CorrectPicker v-model="form.world3" name="world3" :disabled="readonly" required />
              </b-field>
              <b-field label="O" horizontal class="label-long">
                <CorrectPicker v-model="form.world4" name="world4" :disabled="readonly" required />
              </b-field>
              <b-field label="W" horizontal class="label-long">
                <CorrectPicker v-model="form.world5" name="world5" :disabled="readonly" required />
                <a
                  v-if="!form.showAttentionNotes && !form.attentionNotes && form.skipSerial && !readonly"
                  @click.prevent="showNotes('showAttentionNotes', 'attentionNotes')"
                >
                  <b-icon class="field-label" icon="message" title="Add notes" />
                </a>
              </b-field>
            </div>
          </div>
          <div class="column">
            <div class="columns">
              <div class="column is-8">
                <p class="field">Subtract 7 from 100 and keep subtracting 7 from what's left.</p>
              </div>
              <div class="column">
                <ValidationProvider name="skipSerial">
                  <b-checkbox v-model="form.skipSerial">Skip</b-checkbox>
                </ValidationProvider>
              </div>
            </div>
            <div v-if="!form.skipSerial">
              <b-field label="93" horizontal>
                <CorrectPicker v-model="form.serial1" name="serial1" :disabled="readonly" required />
              </b-field>
              <b-field label="86" horizontal>
                <CorrectPicker v-model="form.serial2" name="serial2" :disabled="readonly" required />
              </b-field>
              <b-field label="79" horizontal>
                <CorrectPicker v-model="form.serial3" name="serial3" :disabled="readonly" required />
              </b-field>
              <b-field label="72" horizontal>
                <CorrectPicker v-model="form.serial4" name="serial4" :disabled="readonly" required />
              </b-field>
              <b-field label="65" horizontal>
                <CorrectPicker v-model="form.serial5" name="serial5" :disabled="readonly" required />
                <a
                  v-if="!form.showAttentionNotes && !form.attentionNotes && !readonly"
                  @click.prevent="showNotes('showAttentionNotes', 'attentionNotes')"
                >
                  <b-icon class="field-label" icon="message" title="Add notes" />
                </a>
              </b-field>
            </div>
          </div>
        </div>
        <b-input
          v-if="form.showAttentionNotes || form.attentionNotes"
          v-model="form.attentionNotes"
          placeholder="Enter notes or observations"
          ref="attentionNotes"
          :disabled="readonly"
        />
      </ValidationObserver>
    </CardComponent>

    <CardComponent title="Recall" class="primaryCard">
      <p class="field">Ask for the 3 objects repeated in Registriation section above.</p>
      <b-field label="e.g. Apple" horizontal class="label-long">
        <CorrectPicker v-model="form.recall1" name="recall1" :disabled="readonly" required />
      </b-field>
      <b-field label="e.g. Table" horizontal class="label-long">
        <CorrectPicker v-model="form.recall2" name="recall2" :disabled="readonly" required />
      </b-field>
      <b-field label="e.g. Penny" horizontal class="label-long">
        <CorrectPicker v-model="form.recall3" name="recall3" :disabled="readonly" required />
        <a v-if="!form.showRecallNotes && !form.recallNotes && !readonly" @click.prevent="showNotes('showRecallNotes', 'recallNotes')">
          <b-icon class="field-label" icon="message" title="Add notes" />
        </a>
      </b-field>
      <b-input
        v-if="form.showRecallNotes || form.recallNotes"
        v-model="form.recallNotes"
        placeholder="Enter notes or observations"
        ref="recallNotes"
        :disabled="readonly"
      />
    </CardComponent>

    <CardComponent title="Language" class="primaryCard">
      <div class="columns">
        <div class="column">
          <p class="field">Name these objects.</p>
          <b-field label="Pencil" horizontal class="label-long is-mobile">
            <CorrectPicker v-model="form.pencil" name="pencil" :disabled="readonly" required />
          </b-field>
          <b-field label="Watch" horizontal class="label-long">
            <CorrectPicker v-model="form.watch" name="watch" :disabled="readonly" required />
          </b-field>
          <p class="field">Repeat the following.</p>
          <b-field label="No ifs, ands, or buts" horizontal class="label-long">
            <CorrectPicker v-model="form.repeat" name="repeat" :disabled="readonly" required />
            <a
              v-if="!form.showLanguageNotes && !form.languageNotes && !readonly"
              @click.prevent="showNotes('showLanguageNotes', 'languageNotes')"
            >
              <b-icon class="field-label" icon="message" title="Add notes" />
            </a>
          </b-field>
        </div>
        <div class="column">
          <p class="field">Follow a 3 Stage command.</p>
          <b-field label="Take a paper in your right hand," horizontal class="label-long">
            <CorrectPicker v-model="form.command1" name="command1" :disabled="readonly" required />
          </b-field>
          <b-field label="fold it in half," horizontal class="label-long">
            <CorrectPicker v-model="form.command2" name="command2" :disabled="readonly" required />
          </b-field>
          <b-field label="and put it on the floor." horizontal class="label-long">
            <CorrectPicker v-model="form.command3" name="command3" :disabled="readonly" required />
          </b-field>
        </div>
      </div>
      <b-input
        v-if="form.showLanguageNotes || form.languageNotes"
        v-model="form.languageNotes"
        placeholder="Enter notes or observations"
        ref="languageNotes"
        :disabled="readonly"
      />
    </CardComponent>

    <CardComponent title="Reading" class="primaryCard">
      <p class="field">Read and obey the following.</p>
      <p class="centredDiv reading">CLOSE YOUR EYES</p>
      <CorrectPicker v-model="form.read" name="read" :disabled="readonly" required class="centredField">
        <a v-if="!form.showReadNotes && !form.readNotes && !readonly" @click.prevent="showNotes('showReadNotes', 'readNotes')">
          <b-icon class="field-label" icon="message" title="Add notes" />
        </a>
      </CorrectPicker>
      <b-input
        v-if="form.showReadNotes || form.readNotes"
        v-model="form.readNotes"
        placeholder="Enter notes or observations"
        ref="readNotes"
        :disabled="readonly"
      />
    </CardComponent>

    <CardComponent title="Writing" class="primaryCard">
      <p class="field">Write a sentence.</p>
      <DrawPad height="320px" v-model="form.sentence" :imageUrl="form.sentenceUrl" :isNew="isNew" />
      <CorrectPicker v-model="form.write" name="write" :disabled="readonly" required class="centredField">
        <a v-if="!form.showWriteNotes && !form.writeNotes && !readonly" @click.prevent="showNotes('showWriteNotes', 'writeNotes')">
          <b-icon class="field-label" icon="message" title="Add notes" />
        </a>
      </CorrectPicker>
      <b-input
        v-if="form.showWriteNotes || form.writeNotes"
        v-model="form.writeNotes"
        placeholder="Enter notes or observations"
        ref="writeNotes"
        :disabled="readonly"
      />
    </CardComponent>

    <CardComponent title="Construction" class="primaryCard">
      <p class="field">Copy the following design.</p>
      <div class="centredDiv">
        <img src="@/assets/Neuro-pentagon.png" alt="pentagons" />
      </div>
      <DrawPad height="50%" v-model="form.drawing" :imageUrl="form.drawingUrl" :isNew="isNew" />
      <CorrectPicker v-model="form.construct" name="construct" :disabled="readonly" required class="centredField">
        <a
          v-if="!form.showConstructNotes && !form.constructNotes && !readonly"
          @click.prevent="showNotes('showConstructNotes', 'constructNotes')"
        >
          <b-icon class="field-label" icon="message" title="Add notes" />
        </a>
      </CorrectPicker>
      <b-input
        v-if="form.showConstructNotes || form.constructNotes"
        v-model="form.constructNotes"
        placeholder="Enter notes or observations"
        ref="constructNotes"
        :disabled="readonly"
      />
    </CardComponent>
  </AssessmentForm>
</template>

<script>
import AssessmentForm from "@/views/assessments/AssessmentForm";
import assessmentMixin from "@/mixins/assessmentMixin";

import CardComponent from "@/components/CardComponent.vue";
import CorrectPicker from "@/components/CorrectPicker";
import DrawPad from "@/components/DrawPad";

import SevereEnum from "@/enums/severe.js";
import AssessmentType from "@/enums/assessmentType";
import Dates from "@/common/dates";

export default {
  components: { AssessmentForm, CardComponent, CorrectPicker, DrawPad },
  mixins: [assessmentMixin],
  data() {
    return {
      assessmentType: AssessmentType.Enum.MMSE,
      form: this.getClearFormObject()
    };
  },

  computed: {
    worldScore() {
      return (
        this.checkScore(this.form.world1) +
        this.checkScore(this.form.world2) +
        this.checkScore(this.form.world3) +
        this.checkScore(this.form.world4) +
        this.checkScore(this.form.world5)
      );
    },
    serialScore() {
      return (
        this.checkScore(this.form.serial1) +
        this.checkScore(this.form.serial2) +
        this.checkScore(this.form.serial3) +
        this.checkScore(this.form.serial4) +
        this.checkScore(this.form.serial5)
      );
    },
    registrationScore() {
      return this.checkScore(this.form.object1) + this.checkScore(this.form.object2) + this.checkScore(this.form.object3);
    },
    recallScore() {
      return this.checkScore(this.form.recall1) + this.checkScore(this.form.recall2) + this.checkScore(this.form.recall3);
    },
    languageScore() {
      return (
        this.checkScore(this.form.pencil) +
        this.checkScore(this.form.watch) +
        this.checkScore(this.form.repeat) +
        this.checkScore(this.form.command1) +
        this.checkScore(this.form.command2) +
        this.checkScore(this.form.command3) +
        this.checkScore(this.form.read) +
        this.checkScore(this.form.write) +
        this.checkScore(this.form.construct)
      );
    },
    orientationScore() {
      return (
        this.checkScore(this.form.currentYear) +
        this.checkScore(this.form.currentSeason) +
        this.checkScore(this.form.currentDate) +
        this.checkScore(this.form.currentDay) +
        this.checkScore(this.form.currentMonth) +
        this.checkScore(this.form.country) +
        this.checkScore(this.form.county) +
        this.checkScore(this.form.city) +
        this.checkScore(this.form.building) +
        this.checkScore(this.form.floor)
      );
    },
    score() {
      var score =
        this.orientationScore +
        this.registrationScore +
        Math.max(this.worldScore, this.serialScore) +
        this.recallScore +
        this.languageScore;
      return score;
    },
    rating() {
      const score = this.score;
      if (score < 10) return SevereEnum.Enum.Severe;
      if (score < 25) return SevereEnum.Enum.Moderate;
      if (score < 27) return SevereEnum.Enum.Mild;
      return SevereEnum.Enum.Normal;
    },
    isNew() {
      return !this.form.assessmentId;
    }
  },

  methods: {
    checkScore(v) {
      return v ? 1 : 0;
    },
    showNotes(key, input) {
      this.form[key] = true;
      this.$nextTick(function() {
        this.$refs.AssessmentForm.$slots.default[0].context.$refs[input].focus();
      });
    },
    getClearFormObject() {
      return {
        assessmentId: this.id,
        residentId: null,
        assessmentDate: new Date(),
        gender: null,
        age: null,

        currentYear: null,
        currentSeason: null,
        currentDate: null,
        currentDay: null,
        currentMonth: null,

        country: null,
        county: null,
        city: null,
        building: null,
        floor: null,
        orientationNotes: null,
        showOrientationNotes: false,

        object1: null,
        object2: null,
        object3: null,
        trials: null,
        registrationNotes: null,
        showRegistrationNotes: false,

        world1: null,
        world2: null,
        world3: null,
        world4: null,
        world5: null,

        serial1: null,
        serial2: null,
        serial3: null,
        serial4: null,
        serial5: null,
        attentionNotes: null,
        showAttentionNotes: false,

        recall1: null,
        recall2: null,
        recall3: null,
        recallNotes: null,
        showRecallNotes: false,

        pencil: null,
        watch: null,
        repeat: null,
        command1: null,
        command2: null,
        command3: null,
        languageNotes: null,
        showLanguageNotes: false,

        read: null,
        readNotes: null,
        showReadNotes: false,

        write: null,
        writeNotes: null,
        showWriteNotes: false,

        construct: null,
        constructNotes: null,
        showConstructNotes: false,

        sentence: null,
        sentenceFile: null,
        drawing: null,
        drawingFile: null,

        skipWorld: false,
        skipSerial: false
      };
    },
    formPostData() {
      var postData = new FormData(); // Currently empty

      // set skip values to false
      if (this.form.skipWorld) {
        this.setFalseIfNull(this.form, "world1");
        this.setFalseIfNull(this.form, "world2");
        this.setFalseIfNull(this.form, "world3");
        this.setFalseIfNull(this.form, "world4");
        this.setFalseIfNull(this.form, "world5");
      }
      if (this.form.skipSerial) {
        this.setFalseIfNull(this.form, "serial1");
        this.setFalseIfNull(this.form, "serial2");
        this.setFalseIfNull(this.form, "serial3");
        this.setFalseIfNull(this.form, "serial4");
        this.setFalseIfNull(this.form, "serial5");
      }

      // convert form to FormData so we can append images
      for (const [key, value] of Object.entries(this.form)) {
        if (value) postData.append(key, value);
      }
      postData.set("assessmentDate", Dates.formatYYYYMMDD(this.form.assessmentDate));

      return postData;
    },
    setFalseIfNull(obj, key) {
      if (obj[key] === null) obj[key] = false;
    }
  }
};
</script>

<style scoped>
.reading {
  font-weight: bold;
  font-size: 1.5rem;
  border: 3px black solid;
  padding: 11px;
  width: 300px;
  text-align: center;
}
</style>
