<template>
  <YesNoPicker
    :value="value"
    @input="v => $emit('input', v)"
    :name="name"
    :message="message"
    :disabled="disabled"
    yes="Correct"
    no="Incorrect"
  >
    <slot />
  </YesNoPicker>
</template>

<script>
import YesNoPicker from "@/components/YesNo";

export default {
  name: "CorrectPicker",
  components: { YesNoPicker },
  props: {
    value: { type: Boolean, default: null },
    name: { type: String, required: true },
    message: { type: String, required: false },
    disabled: { type: Boolean, required: false }
  }
};
</script>
